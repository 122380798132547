<template>
    <button @click="show = true" class="btn ml-2 btn-success btn-sm">
        <i class="fa fa-upload"></i> Tải dữ liệu
        <b-modal v-model="show" title="Tải danh sách tổ chức (ORG)" @close="close" no-close-on-esc no-close-on-backdrop
                 no-enforce-focus>
            <!-- <div class="form-group">
                <label><b>Tải xuống tập tin mẫu</b></label>
                <div>
                    <button @click="downloadTemplate" class="btn btn-sm btn-secondary mr-1 mb-2">
                        <i class="fa fa-file-excel"></i> {{templateName}}
                    </button>
                </div>
            </div> -->
            <div class="form-group">
                <label><b>Chọn tập tin</b></label>
                <b-form-file v-model="file" :disabled="reading||totalRows>0"
                             placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             drop-placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             browse-text="Chọn tập tin" 
                             accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
            </div>
            <b-form-checkbox v-model="updated" value="1" unchecked-value="0">
                Cho phép cập nhật thông tin tổ chức khi trùng Tên đầy đủ; Năm thành lập; Tỉnh-Huyện-Xã. Cho phép cập nhật giai đoạn khi trùng Ngày bắt đầu - Ngày kết thúc giai đoạn
            </b-form-checkbox>
            <div class='mb-3' ></div>
            <div v-if="reading" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang đọc file. Quá trình này có thể sẽ tốn nhiều thời gian nếu file tải lên có dung lượng lớn. Xin đừng tắt cửa sổ trình duyệt cho đến khi quá trình tải lên hoàn tất!</span>
            </div>
            <div v-if="importing" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang tải dữ liệu lên... Xin hãy kiên nhẫn, việc tải lên sẽ mất một chút thời gian nếu file của bạn quá lớn</span>
            </div>
            <div class="mb-3" v-if="totalRows">
                <b-progress :max="totalRows">
                    <b-progress-bar :value="successCount+errorCount"
                                    :label="`${(((successCount + errorCount) / totalRows) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
                <div class="text-center mt-2">Xử lý {{ successCount + errorCount }}/{{ totalRows }} dòng | <span :class="[errorCount && errorCount > 0? 'text-danger':'']" >Vấn đề: {{ errorCount }} </span>
                    <a v-if='errorCount > 0' @click="downloadErrors" class="btn-link text-primary" href="javascript:;">[Chi tiết]</a>
                </div>
            </div>
            <template v-slot:modal-footer>
                <button class="btn btn-secondary" @click="cancel" v-if="!reading&&importing">Dừng tải</button>
                <button class="btn btn-primary" @click="reset" v-if="!reading&&!importing&&totalRows>0">Làm lại</button>
                <button v-if="totalRows===0" class="btn btn-primary" @click="process" :disabled="reading||importing">
                    <b-spinner small v-if="reading||importing"></b-spinner>
                    Tải lên
                </button>
            </template>
        </b-modal>
    </button>
</template>

<script>
    import Import from "@/mixins/Import";
  
    export default {
        props: ['params'],
        mixins: [Import],
        data() {
            return {
                indexRow: 4,
                reloadBus: "reloadOrg",
                service: '/org',
                templateName: 'to_chuc.xlsx',
                headers: [
                    {header: 'name', key: 'name', label: 'Tên đầy đủ tổ chức*', width: 30, desc: "Nhập định dạng chữ (Theo Tên Đăng ký chính thức)"},
                    {header: 'short_name', key: 'short_name', label: 'Tên viết tắt', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'founded_year', key: 'founded_year', label: 'Năm thành lập*', width: 30, desc: "Nhập định dạng năm (yyyy)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 15, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'type', key: 'type', label: 'Loại hình can thiệp*', width: 30, desc: "Nhập một hoặc nhiều giá trị số từ 1 đến 3 (Trong đó: " + this.$params.org.typeInterventionOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp*', width: 20, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp*', width: 20, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'result', key: 'result', label: 'Kết quả cải thiện', width: 20, desc: "Nhập 01 giá trị từ 1 đến 3 (Trong đó: " + this.$params.org.resultOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"}
                ],
            }
        },
        methods: {
            async save(item) {
                if (this.updated == 1) {
                    await this.$service.post(`${this.service}/import?t=u`, item);
                } else {
                    await this.$service.post(`${this.service}/import?t=c`, item);
                }
            },
            getItem(headerRow, row) {
                let item = {};
                for (let j = 1; j <= headerRow.cellCount; j++) {
                    let key = headerRow.getCell(j).value;
                    let value;
                    if (row.getCell(j).isHyperlink) {
                        value = row.getCell(j).value.text;
                    } else {
                        value = row.getCell(j).value;
                    }
                    if (value !== null && value !== '') {
                        if (typeof value === 'string') {
                            value = value.trim();
                        }
                        if (['start_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.start_time = value;
                        } else if (['end_time'].includes(key)) {
                            value = this.getDate(value, "DD/MM/YYYY");
                            item.end_time = value;
                        } else if (['type'].includes(key)) {
                            let result = [];
                            let key = this.getKeyOption(this.$params.org.typeInterventionOptions);
                            if (value.toString().includes(",")) {
                                let array = value.split(',');
                                array.forEach((entry) => {
                                    if (key.includes(entry.trim())) {
                                        result.push(entry.trim());
                                    } else {
                                        result.push(-1);
                                    }
                                });
                            } else {
                                if(key.includes(value.toString())){
                                    result.push(value.toString());
                                }   else {
                                    result.push(-1);
                                }
                            }
                            item.type = result;
                        } else if (['result'].includes(key)) {
                            let key = this.getKeyOption(this.$params.org.resultOptions);
                            if(key.includes(value.toString().trim())){
                                item.result = value.toString().trim();
                            } else {
                                item.result = -1;
                            }
                        } else if (['short_name'].includes(key)) {
                            item.short_name = value.toString();
                        } else if (['name'].includes(key)) {
                            item.name = value.toString();
                        } else {
                            item[key] = value;
                        }
                    } else {
                        item[key] = null;
                    }
                }
                return item;
            }
        }
    }
</script>
