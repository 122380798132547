<template>
    <div class="title-template-table  table-responsive">
        <label class="font-weight-normal">{{label}}</label>
        <table class="table table-hover table-head-fixed table-hover mb-3">
            <thead>
                <tr>

                    <th class="text-left">Ngày bắt đầu</th>
                    <th class="text-left">Ngày kết thúc</th>
                    <th class="text-left">Loại hình can thiệp</th>
                    <th class="text-left">Kết quả cải thiện</th>
                    <th class="text-left" v-if="!view">
                        <span class="btn-add float-right pr-2" @click="create">
                            <i class="icon16-plus-circle-gadient"></i> Thêm mới
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody v-if="!items || !items.length" >
                <tr>
                    <td colspan="5" class="text-center text-danger">Danh sách dữ liệu trống</td>
                </tr>
            </tbody>
            <draggable v-model="items" @change="move" tag="tbody">
                <tr v-for="(item, index) in items" :key="index" class="tr-draggable">

                    <td class="text-left">{{item.start_time | dateFormat}}</td>
                    <td class="text-left">{{item.end_time  | dateFormat}}</td>
                    <td class="text-left">
                        <span v-if="item.types" >
                            <span v-for="typeIntervention in item.types" :key="typeIntervention" class="badge bg-gray ml-1" >
                                <span v-if="$params.org.typeInterventionOptions[typeIntervention]">
                                    {{$params.org.typeInterventionOptions[typeIntervention].text}}
                                </span>
                            </span>
                        </span>
                    </td>
                    <td class="text-left" v-if="item.result">{{ $params.org.resultOptions.find(o => o.value == item.result) ? $params.org.resultOptions.find(o => o.value == item.result).text : '' }}</td>
                    <td class="text-left" v-else></td>
                    <td class="text-right" v-if="!view">
                        <button type="button" class="btn-sm btn btn-sm btn-outline-gray mb-1">
                            <i class="fa fa-arrows-alt mr-0"></i>
                        </button>
                <g-button :loading="updateLoading" size="sm" variant="outline-info" class="ml-1 mb-1" icon="pencil" @click='update(index)'/>
                <g-button :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(index)' />
                </td>
                </tr>
            </draggable>
        </table>
        <b-modal v-model="showForm" :title="`${formTitle} ${label}`" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md'>
                    <form-date-time :disabled="formModel.id" :required='true' label="Ngày bắt đầu can thiệp" :model="formModel" :errors="formErrors" attribute="start_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md'>
                    <form-date-time :disabled="formModel.id" :required='true' label="Ngày kết thúc can thiệp" :model="formModel" :errors="formErrors" attribute="end_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
            </div> 
            <form-select-multiple :close-on-select='false' :required='true'  label="Loại hình can thiệp" :model="formModel" attribute="types" :errors="formErrors" :options="typeInterventionOptions" />

            <form-select label="Kết quả cải thiện" :model="formModel" attribute="result" :errors="formErrors" :options="$params.org.resultOptions" />
            <template v-slot:modal-footer>
                <b-button variant="secondary" class="float-right" @click="showForm = false">Huỷ</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="save">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>

</template>

<script>
    import draggable from 'vuedraggable'
    import crud from '@/mixins/CRUD';
    import moment from 'moment';
    export default {
        mixins: [crud],
        components: {draggable},
        props: ['model', 'attribute', 'label', 'errors', 'view'],
        data() {
            return {
                defaultFormModel: {
                    types: [],
                    result: null,
                    position: 1,
                },
                files: {},
                items: this.model[this.attribute] ? this.model[this.attribute] : [],
                state: null,
                errorTxt: '',
                formErrors: {},
                typeInterventionOptions: [
                    {value: 1, text: "1. Tài trợ công cụ, thiết bị"},
                    {value: 2, text: "2. Tài trợ nguồn tài chính"},
                    {value: 3, text: "3. Nâng cao năng lực"}
                ],
            }
        },
        watch: {
            model: {
                handler(val) {
                    this.state = null;
                    this.errorTxt = '';
                    if (val[this.attribute] && val[this.attribute] !== null) {
                        this.items = val[this.attribute];
                    } else {
                        this.items = null;
                    }
                }, deep: true
            },
            items(val) {
                this.state = null;
                this.errorTxt = '';
                this.model[this.attribute] = val;
            },
            errors(val) {
                let hasErr = false;
                if (val && Object.keys(val).length) {
                    Object.keys(val).forEach(key => {
                        if (key === this.attribute) {
                            this.state = false;
                            this.errorTxt = val[key].join(";");
                            hasErr = true;
                        }
                    });
                }
                if (!this.errorOnly) {
                    this.state = !hasErr;
                }
            }
        },
        methods: {
            validators: function () {
                this.formErrors = {};
                if (!this.formModel.start_time) {
                    this.formErrors.start_time = ["Ngày bắt đầu không được để trống"];
                }
                if (!this.formModel.end_time) {
                    this.formErrors.end_time = ["Ngày kết thúc không được để trống"];
                }
                if (this.formModel.start_time && this.formModel.end_time && (this.formModel.start_time - this.formModel.end_time) > 0) {
                    this.formErrors.start_time = ["Ngày bắt đầu không được lớn hơn ngày kết thúc"];
                }
                if (this.formModel.start_time && this.formModel.end_time && !this.formModel.id) {
                    let k = this.formModel.start_time + "c" + this.formModel.end_time;
                    let ob = [];
                    for (var key in this.items) {
                        var obj = this.items[key];
                        ob.push(obj.start_time + "c" + obj.end_time);
                    }
                    if (ob.includes(k)) {
                        let ste = "Giai đoạn " + moment(this.formModel.start_time).format('DD/MM/YYYY') + "-" + (moment(this.formModel.end_time).format('DD/MM/YYYY')) + " đã tồn tại";
                        this.formErrors.start_time = [ste];
                    }
                }


                if (this.formModel.types.length == 0) {
                    this.formErrors.types = ["Loại hình can thiệp không được để trống"];
                }
            },
            save: function (evt) {
                this.items = !this.items || !this.items.length ? [] : this.items;
                evt.preventDefault();
                this.validators();
                if (Object.keys(this.formErrors).length > 0) {
                    return false;
                }
                if (!this.formModel.id) {
                    this.items.unshift(this.formModel);
                    this.load(this.items);
                } else {
                    this.items[this.formModel.id] = {...this.formModel};
                }
                this.showForm = false;

            },
            move: function () {

            },
            update: function (index) {
                this.formModel = {};
                this.formTitle = "Cập nhật";
                this.formErrors = [];
                this.showForm = true;
//             
                this.formModel = {...this.items[index]};
                this.formModel.id = `${index}`;
            },
            del: function (index) {
                this.$swal({
                    title: "Bạn chắc chắn muốn xóa chứ?",
                    text: "Sẽ không thể khôi phục lại dữ liệu đã xóa!",
                    icon: "warning",
                    showCancelButton: true
                }).then((result) => {
                    if (result.value) {
                        if (result.value) {
                            if (index >= 0) {
                                this.items.splice(index, 1);
                            }
                        }
                    }
                });
            },
            load() {
            },
        },
        mounted() {
            this.items = this.model[this.attribute] ? this.model[this.attribute] : [];
            this.load();
        }
    }
</script>